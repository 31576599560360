// Entry point for the build script in your package.json
// import "@hotwired/turbo-rails"
import "./controllers"
import * as bootstrap from "bootstrap"
window.Dropdown = bootstrap.Dropdown;

require('bootstrap-select');

import jquery from 'jquery';
window.jQuery = jquery;
window.$ = jquery;


$.fn.selectpicker.Constructor.DEFAULTS.noneSelectedText = document.querySelector('meta[name="nothingSelectedText"]').content

$(function () {
  if( /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent) ) {
    $('select').selectpicker('mobile');
  } else {
    $('select').selectpicker();
  }
});
